<template>
    <div style="min-height: 100vh;background-color: #F8F9FA;">
        <van-skeleton title :row="3" v-if="loading" />
        <div v-else>
            <!-- 商品信息 -->
            <div class="good" style="padding-bottom: 0;">
                <div class="goodTop">
                    <img src="../../../images/order/poster.png" alt="" class="goodPic">
                    <div class="goodInfo">
                        <span>{{ commodityName }}</span>
                        <div>
                            <span class="price">￥{{ versionConfiguration.discountedPrice }}</span>
                            <span class="num"><van-icon name="cross" size="8px" color="#999999" /> 1</span>
                        </div>
                    </div>
                </div>
                <div v-if="samplingStatus != undefined || orderStatus == 3" class="wrapperBottom display_flex"
                    style="justify-content: space-between; padding:16px 0;">
                    <div class="status">
                        <div class="display_flex" style="width:10px">
                            <div :class="['round', samplingStatus > 0 ? 'active' : '']"></div>
                            <div class="rowline" :style="{ backgroundColor: samplingStatus > 1 ? '#1477FD' : '' }"></div>
                        </div>
                        <div style="margin-top: 11px;">已预约</div>
                    </div>
                    <div class="status">
                        <div class="display_flex" style="width:10px">
                            <div :class="['round', samplingStatus > 1 ? 'active' : '']"></div>
                            <div class="rowline" :style="{ backgroundColor: samplingStatus > 2 ? '#1477FD' : '' }"></div>
                        </div>
                        <div style="margin-top: 11px;">检测中</div>
                    </div>
                    <div class="status">
                        <div class="display_flex" style="width:10px">
                            <div :class="['round', samplingStatus > 2 ? 'active' : '']"></div>
                            <div class="rowline" :style="{ backgroundColor: samplingStatus > 3 ? '#1477FD' : '' }"></div>
                        </div>
                        <div style="margin-top: 11px;">审核中</div>
                    </div>
                    <div class="status">
                        <div class="display_flex" style="width:10px">
                            <div :class="['round', samplingStatus > 3 ? 'active' : '']"></div>
                        </div>
                        <div style="margin-top: 11px;">出报告</div>
                    </div>
                </div>
            </div>

            <!-- 采样信息 -->
            <van-collapse v-if="samplingInfo != undefined" v-model="activeNames">
                <van-collapse-item title="采样信息" name="sampleInfo">
                    <div class="sampleCollapse">
                        <div class="wrapperTop">
                            <div class="wrapperBox">
                                <div class="wrapperText">预约时间</div>
                                <div class="wrapperValue">{{ samplingInfo.appointmentTime }}</div>
                            </div>
                            <div class="wrapperBox">
                                <div class="wrapperText">采样地址</div>
                                <div class="wordBreak wrapperValue">{{ samplingInfo.address }}</div>
                            </div>
                            <div class="wrapperBox">
                                <div class="wrapperText">采样机构</div>
                                <div class="wordBreak wrapperValue">{{ samplingInfo.institutionName }}</div>
                            </div>
                            <div class="wrapperBox">
                                <div class="wrapperText">订单号</div>
                                <div class="wrapperValue">{{ orderNumber }}</div>
                            </div>
                        </div>
                        <div class="wrapperBottom">
                            提示<span class="xing">*</span> 采样时请携带身份证等身份证明证件，并将预约码填写在送检单上
                        </div>
                    </div>
                </van-collapse-item>
            </van-collapse>

            <!-- 健康调查问卷 -->
            <div class="health" @click="healthSurvey">健康调查问卷
                <van-icon name="arrow" size="14px" color="#999999" />
            </div>

            <!-- 加购商品 -->
            <div class="exchangeGood" v-if="isAddtional">
                <div class="wrapperTitle" style="padding:0 0 8px 0;">
                    <div class="line"></div>
                    加购商品
                </div>
                <div class="exchangeGoodInfo">
                    <img :src="osspath + additionalVersion.image" alt="" class="exchangeGoodPic">
                    <div class="exchangeGoodContent">
                        <div style="font-size: 14px;color: #333333;">
                            {{ additionalName }}
                        </div>
                        <div class="version">{{ additionalVersion.name }}</div>
                        <div>
                            <span style=" color:#E02020 ;">￥{{ additionalVersion.discountedPrice
                            }}</span>
                            <span style="font-size: 14px;color: #999999;line-height: 24px;float: right;"><van-icon
                                    name="cross" size="8px" color="#999999" /> 1</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 快递信息 -->
            <div class="wrapper" v-if="isAddtional"
                :style="{ paddingBottom: '0px', marginTop: isAddtional ? '12px' : '0px' }">
                <div class="wrapperTitle" style="padding:0 0 16px 0;">
                    <div class="line"></div>
                    <span>快递信息</span>
                </div>
                <div class="wrapperTop">
                    <div class="wrapperBox">
                        <div class="wrapperText">快递单号</div>
                        <div class="wordBreak wrapperValue">{{ (courierNo == '' || courierNo == undefined) ? '暂无' :
                            courierNo }}</div>
                    </div>
                </div>
                <div class="wrapperBottom">
                    提示<span class="xing">*</span> 商品内容根据检测结果进行制作生成，请耐心等待
                </div>
            </div>


            <!-- 收货信息 -->
            <van-collapse v-model="activeNames" v-if="isAddtional">
                <van-collapse-item title="收货信息" name="location">
                    <div class="wrapperBox">
                        <div class="wrapperText">收货人</div>
                        <div>{{ receivingInfo.userName }}</div>
                    </div>
                    <div class="wrapperBox">
                        <div class="wrapperText">联系电话</div>
                        <div>{{ receivingInfo.phone }}</div>
                    </div>
                    <div class="wrapperBox">
                        <div class="wrapperText">所在地区</div>
                        <div>{{ receivingInfo.province + '-' + receivingInfo.city + '-' + receivingInfo.area }}</div>
                    </div>
                    <div class="wrapperBox" style="margin-bottom: 0;">
                        <div class="wrapperText">详细地址</div>
                        <div class="wordBreak">{{ receivingInfo.detailedAddress }}</div>
                    </div>
                </van-collapse-item>
            </van-collapse>

            <!-- 订单信息 -->
            <div class="orderInfo" :style="{ 'marginTop': isAddtional ? '12px' : '0' }">
                <div class="wrapperTitle" style="padding:0 0 16px 0;">
                    <div class="line"></div>
                    <span>订单信息</span>
                </div>
                <div class="wrapperBox">
                    <div class="wrapperText">订单金额</div>
                    <div style="color:#E02020">￥{{ totalAmount }}</div>
                </div>
                <div class="wrapperBox">
                    <div class="wrapperText">下单时间</div>
                    <div class="wrapperValue">{{ createAt }}</div>
                </div>
                <div class="wrapperBox" style="margin-bottom: 0;">
                    <div class="wrapperText">订单状态</div>
                    <div>{{ status[orderStatus] }}</div>
                </div>
            </div>

            <!-- 底部按钮 -->
            <div v-if="orderStatus == 0 || orderStatus == 3">
                <div style="height:92px;width:100%;"></div>
                <div class="bottom">
                    <button class="bottomButton timeout" @click="payHandle" v-if="orderStatus == 0" :disabled="isClickPay">
                        剩余支付时间<van-count-down :time="time" @finish="countDownFinish" format="HH/mm/ss ">
                            <template v-slot="timeData">
                                <span class="item">{{ timeData.minutes > 9 ? timeData.minutes : '0' +
                                    timeData.minutes }}:{{ timeData.seconds > 9 ? timeData.seconds : '0' + timeData.seconds
    }}</span>
                            </template>
                        </van-count-down>秒 立即支付
                    </button>
                    <button class="bottomButton" @click="viewReport" v-if="orderStatus == 3">查看报告</button>
                </div>
            </div>

            <van-dialog v-model="codeShow" title="验证码" show-cancel-button confirm-button-color="#1477FD" @confirm="confirm"
                @cancel="cancel" :before-close="onBeforeClose">
                <div class="codeBox">
                    <div v-for="(item, index) in len" :key="index" class="code">
                        <span v-if="code.length > index"></span>
                    </div>
                    <van-field v-model="code" type="number" maxlength="6" />
                </div>
            </van-dialog>
        </div>
    </div>
</template>
<script>
import { Dialog } from 'vant';
import { osspath } from "../../../util/config"
import { throttle } from '../../../util/util';
import { Toast } from 'vant';
import OrderApi from '../api/index'
const orderApi = new OrderApi()
const status = ['待支付', "已预约", "检测中", "已完成", "已关闭", "已取消", '审核中']
export default {
    data() {
        return {
            id: '',  //订单id
            orderNumber: '',  //订单号
            activeNames: [],
            code: '',  //短信验证码
            codeShow: false,
            len: 6,
            time: 30 * 60 * 1000, //倒计时
            deadline: 30 * 60 * 1000,  //最长时间
            timeData: {},
            orderStatus: null,  //订单状态
            samplingStatus: null,  //采样检测状态
            commodityName: '',
            versionConfiguration: {},
            additionalName: '',
            additionalVersion: {},
            receivingInfo: {},
            isAddtional: false,  //是否选择了加购商品
            samplingInfo: {},  //采样信息
            createAt: '',
            status: status,
            totalAmount: '',  //订单总金额
            courierNo: '',//快递单号
            osspath: osspath,
            flag: 0,
            loading: true,
            isClickPay: false
        }
    },
    created() {
    },
    beforeMount() {
        this.id = this.$route.query.id
        this.getData()
    },
    mounted() {

    },
    computed: {
    },
    methods: {
        // 获取详情信息
        async getData() {
            let res = await orderApi.orderDetail(this.id)
            let data = res.data
            this.orderNumber = data.orderNumber
            this.createAt = data.createAt
            this.orderStatus = data.orderStatus
            this.totalAmount = data.totalAmount
            this.commodityName = data.commodityInfo.commodityName
            this.versionConfiguration = data.commodityInfo.versionConfiguration
            this.samplingInfo = data.samplingInfo
            this.samplingStatus = data.samplingStatus
            localStorage.setItem('healthSurvey', JSON.stringify(data.healthQuestionnaire))

            // 是否有加购商品
            this.additionalName = data.additionalInfo == undefined ? '' : data.additionalInfo.additionalName
            if (this.additionalName != '') {
                // 已经勾选加购
                this.isAddtional = true
                this.additionalVersion = data.additionalInfo.additionalVersion
                this.courierNo = data.additionalInfo.courierNo
                this.receivingInfo = data.receivingInfo
            }
            if (this.orderStatus == 0) {
                // 订单为待支付的状态,计算订单是否超时
                this.computeTimeout()
            }
            if (this.orderStatus == 3) {
                this.samplingStatus = 4
            }

            this.loading = false
        },
        // 计算倒计时
        computeTimeout() {
            const replaceTime = this.createAt.replace(/-/g, "/")
            // 计时时间 - （当前时间 - 创建时间）
            this.time = this.deadline - (new Date().getTime() - new Date(replaceTime).getTime())
        },

        // 防抖动
        payHandle: throttle(function () {
            this.onSubmit()
        }, 2000),

        //  提交订单
        onSubmit() {
            this.isClickPay = 'disable'
            let params = {
                id: this.id,
            }

            let that = this
            orderApi.pay(params).then((res) => {
                let data = res.data
                function onBridgeReady() {
                    WeixinJSBridge.invoke(
                        'getBrandWCPayRequest', {
                        "appId": data.appId,     //公众号ID，由商户传入     
                        "timeStamp": data.timeStamp,         //时间戳，自1970年以来的秒数     
                        "nonceStr": data.nonceStr, //随机串     
                        "package": data.packageVal,
                        "signType": data.signType,         //微信签名方式：     
                        "paySign": data.paySign //微信签名 
                    },
                        function (res) {
                            if (res.err_msg == "get_brand_wcpay_request:ok") {
                                // 使用以上方式判断前端返回,微信团队郑重提示：
                                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                that.doItPerSecond()
                            } else {
                                Toast("支付失败")
                                that.isClickPay = false
                            }
                        });
                }
                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                    }
                } else {
                    onBridgeReady();
                }
            })
        },
        // 检测是否支付成功 5次及以上就返回支付失败结果
        doItPerSecond() {
            //循环执行，每隔1秒钟执行一次 1000
            let t1 = setInterval(findreturn, 1000);
            const that = this;
            function findreturn() {
                that.flag++;
                if (that.flag >= 6) {
                    // console.log("请求超时......");
                    that.flag = 0;
                    //去掉定时器的方法
                    clearInterval(t1);
                    Toast('支付失败')
                    that.isClickPay = false
                } else {
                    orderApi.payCallback(that.id).then((res) => {
                        if (res.code == 0) {
                            //去掉定时器的方法
                            clearInterval(t1);
                            that.getData();
                            that.isClickPay = false
                        }
                    })
                }
            }
        },
        // 去健康调查问卷页面
        healthSurvey() {
            localStorage.setItem('isReadonly', 'true')
            this.$router.push('/orderHealth')
        },
        // 订单超时，取消订单
        async endOrder() {
            let res = await orderApi.endOrder(this.id)
        },
        // 点击查看报告
        viewReport() {
            Dialog.confirm({
                title: '提示',
                message: '为保护受检人隐私<br/>查看报告前需要向受检人手机号<br/>发送验证码进行校验',
                cancelButtonColor: '#999999',
                confirmButtonColor: '#1477FD',
                width: '282px',
            }).then(() => {
                // 发送验证码
                this.sendCode()
            }).catch(() => {
                // on cancel
            });
        },
        // 获取短信验证码
        sendCode() {
            orderApi.sendCode(this.id).then(() => {
                // 展示验证码弹框
                this.codeShow = true
            }).catch(() => {
                this.codeShow = true
            })
        },
        // 根据验证码获取报告信息
        async getReport() {
            let res = await orderApi.getReport(this.id, this.code)
            this.clearStore()
            localStorage.setItem('reportData', JSON.stringify(res.data))
            this.$router.push("/report/list")
        },
        // 清除缓存
        clearStore() {
            localStorage.removeItem('reportData')
            localStorage.removeItem('reportList')
            localStorage.removeItem('report')
        },
        // 验证码弹框点击确定
        confirm() {
            if (this.code.length == 6) {
                // 这里提交请求
                this.getReport()
            }
        },
        // 弹框点击取消
        cancel() {
            this.code = ''
        },
        // 验证码输入弹框关闭前请求
        async onBeforeClose(action, done) {
            if (action === "confirm") { // 点击确定走这里
                if (this.code.length != 6) {
                    return done(false)
                } else {
                    done(true) // 关闭弹窗, true可以省略
                    this.code = ''
                }
            } else { // 点击取消走这里
                done(true) // 关闭弹窗, true可以省略
            }
        },
        // 倒计时结束
        countDownFinish() {
            //判断状态为待支付
            this.endOrder()
            this.getData()
        }
    },
}
</script>
<style lang="scss" scoped>
.good {
    padding: 16px;
    box-sizing: border-box;
    background: #FFFFFF;

}

.goodTop {
    display: flex;
    align-items: center;
    padding-bottom: 16px;

    .goodPic {
        width: 82px;
        height: 104px;
        border-radius: 8px;
        border: 1px solid #E6E6E6;
        overflow: hidden;
        flex-shrink: 0
    }
}

.goodInfo {
    flex: 1;
    height: 104px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    color: #333333;

    .price {
        font-size: 20px;
        color: #E02020;
        line-height: 28px;
    }

    .num {
        color: #999999;
        line-height: 28px;
        float: right;
        font-size: 16px;
    }
}

.health {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: #1477FD;

    .healthButton {
        width: 96px;
        height: 40px;
        background: #1477FD;
        border-radius: 8px;
        color: #FFFFFF;
        text-align: center;
        line-height: 40px;
    }
}

.exchangeGood {
    background: #FFFFFF;
    padding: 16px;
    box-sizing: border-box;


    .chooseVersion {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 11px;
    }
}

.exchangeGoodInfo {
    display: flex;
    align-items: center;

    .exchangeGoodPic {
        width: 90px;
        height: 90px;
        border-radius: 8px;
        border: 1px solid #E6E6E6;
        flex-shrink: 0
    }

    .exchangeGoodContent {
        margin-left: 16px;
        height: 90px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex: 1;
    }
}

.version {
    font-size: 12px;

    color: #999999;
    line-height: 17px;
    margin-top: 17px;
}


.wrapper {
    margin-top: 12px;
    padding: 16px;
    background-color: #fff;
}

.wrapperTitle {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    color: #333333;
    line-height: 22px;

    .line {
        width: 2px;
        height: 10px;
        background: #1477FD;
        border-radius: 1px;
        margin-right: 8px;
    }
}

.wrapperBox {
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    font-size: 14px;

    color: #333333;
}

.wrapperText {
    width: 86px;
    flex-shrink: 0
}

.wrapperValue {
    flex: 1;
}

.wrapperBottom {
    font-size: 12px;
    color: #E02020;
    line-height: 17px;
    padding: 10px 0;
    border-top: 1px solid #E6E6E6;
}

.mailLocation {
    margin-top: 12px;
    padding: 16px;
    background-color: #fff;
}

.orderInfo {
    background: #FFFFFF;
    padding: 16px;
    box-sizing: border-box;
    margin-top: 12px;
}

.status {
    font-size: 14px;

    color: #333333;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.round {
    width: 10px;
    height: 10px;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 50%;
    flex-shrink: 0
}

.rowline {
    // width: 100px;
    width: 25vw;
    height: 2px;
    background: #E6E6E6;
    border-radius: 1px;
    flex-shrink: 0
}

.active {
    border: 1px solid #1477FD;
    background-color: #E6F0FF;
}

.codeBox {
    display: flex;
    align-items: center;
    padding: 28px 20px;
    justify-content: space-between;

    .van-cell {
        width: 100%;
        position: absolute;
        opacity: 0;
    }
}

.code {
    width: 32px;
    height: 32px;
    background: #F8F9FA;
    border-radius: 8px;
    padding: 0;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    border: 0;

    span {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        background-color: #555;
        display: inline-block;
    }
}

.timeout {
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
        font-size: 14px;

        color: #FFFFFF;
    }
}

.van-dialog {
    width: 282px;
}

// 折叠面板样式
.van-collapse-item {
    ::v-deep .van-cell {
        padding: 16px !important;
        margin-top: 12px;
    }

    ::v-deep .van-cell__title {
        font-size: 16px !important;
        display: flex;
        align-items: center;

    }

    ::v-deep .van-icon {
        font-size: 14px;
    }

    ::v-deep .van-cell::after {
        border: none !important;
    }

    ::v-deep .van-cell__title::before {
        content: '';
        width: 2px;
        height: 10px;
        background: #1477FD;
        border-radius: 1px;
        margin-right: 8px;
        display: inline-block;
    }

    ::v-deep .van-collapse-item__title .van-cell__right-icon::before {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    ::v-deep .van-collapse-item__title--expanded .van-cell__right-icon::before {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

.van-skeleton {
    padding: 0 !important;
}

// 不能滚动
.van-skeleton__content {
    height: 100vh;
    overflow: hidden;
}

.van-skeleton__title {
    width: 100% !important;
    height: 136px;
    background-color: #fff;
}

.van-skeleton__row {
    width: 100% !important;
    height: 162px;
    margin-top: 12px;
    background-color: #fff;
}

.xing {
    display: inline-block;
    vertical-align: middle;
    line-height: 17px;
    font-size: 12px;
}
</style>
